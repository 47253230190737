import { FieldPolicy, InMemoryCache } from "@apollo/client";
import {
  offsetLimitPagination,
  relayStylePagination,
} from "@apollo/client/utilities";

type KeyArgs = FieldPolicy<any>["keyArgs"];

export function cursorLimitPagination(keyArgs: KeyArgs = false) {
  return {
    keyArgs,
    merge(existing, incoming, { readField }) {
      const posts = existing ? { ...existing.posts } : {};
      incoming?.posts?.forEach((d) => {
        posts[readField("postId", d)] = d;
      });
      return {
        since: incoming.since,
        hasMore: incoming.hasMore,
        posts,
      };
    },
    read(existing) {
      if (existing) {
        return {
          since: existing.since,
          hasMore: existing.hasMore,
          posts: Object.values(existing.posts),
        };
      }
    },
  };
}

const cache = new InMemoryCache({
  typePolicies: {
    ActivityFeed: {
      keyFields: ["actId"],
    },
    Club: {
      keyFields: ["clubId"],
    },
    Stock: {
      keyFields: ["securityId"],
    },
    Hashtag: {
      keyFields: ["hashtagName"],
    },
    Post: {
      keyFields: ["postId"],
    },
    Comment: {
      keyFields: ["commentId"],
    },
    Reply: {
      keyFields: ["replyId"],
    },
    DollarTag: {
      keyFields: ["dollarTagId"],
    },
    Poll: {
      keyFields: ["pollId"],
    },
    Notification: {
      keyFields: ["notificationId"],
    },
    Vote: {
      keyFields: ["voteId"],
    },
    UpcomingEventsList: {
      keyFields: ["eventId"],
    },
    popUp: {
      keyFields: ["popUpType"],
    },
    UserRecentSearchOutput: {
      keyFields: ["searchId"],
    },
    HighlightedAchievements: {
      keyFields: ["achievementType"],
    },
    FeedList: {
      keyFields: ["feedTypeId"],
    },
    Exchange: {
      keyFields: ["Exchange"],
      fields: {
        IsActive: {
          read(existing) {
            return existing ?? false; // Default value for isActive
          },
        },
      },
    },
    ApprovalRequests: {
      keyFields: ["approvalRequestId"],
    },
    Query: {
      fields: {
        getNotifications: offsetLimitPagination(["filter"]),
        getFeedPosts: offsetLimitPagination(),
        getFeedByType: offsetLimitPagination(["feedType"]),
        getRecommendedPosts: offsetLimitPagination(),
        getSelfFeed: offsetLimitPagination(),
        getProfilePostsV2: offsetLimitPagination(["data"]),
        getClubPosts: offsetLimitPagination(["clubId"]),
        getPostsByHashTagV2: offsetLimitPagination(["hashTagName"]),
        getStocksBySearchTextForWatchlist: offsetLimitPagination([
          "searchText",
        ]),
        getWatchlist: offsetLimitPagination(),
        getWatchlistV2: offsetLimitPagination(["type"]),
        getShareFeedForPost: offsetLimitPagination(["postId"]),
        getVoterList: offsetLimitPagination(["pollId"]),
        getClubMediaPosts: offsetLimitPagination(["data"]),
        getMediaPosts: offsetLimitPagination(["data"]),
        getTrendingStocksV2: offsetLimitPagination(),
        getTrendingHashTagsV2: offsetLimitPagination(),
        getTrendingSectorsByDollarTag: offsetLimitPagination(),
        getTrendingStocksByDollarTag: offsetLimitPagination(),
        unifiedSearch: offsetLimitPagination(["searchText", "type"]),
        getStocksBySearchTextViaDollarTagForWatchlist: offsetLimitPagination([
          "searchText",
        ]),
        getPostByDollarTag: offsetLimitPagination(["dollarTagId"]),
        getClubTrendingStocksViaDollarTag: offsetLimitPagination(["clubId"]),
        getClubTrendingSectorsViaDollarTag: offsetLimitPagination(["clubId"]),
        getClubTrendingHashtagsV2: offsetLimitPagination(["clubId"]),
        getMarketExperts: offsetLimitPagination(),
        getVerifiedUsers: offsetLimitPagination(),
        getPopularUsers: offsetLimitPagination(),
        getUserFollowings: offsetLimitPagination(["userId"]),
        getUserFollowers: offsetLimitPagination(["userId"]),
        getHashtagFollowings: offsetLimitPagination(["userId"]),
        getBookmarkV2: offsetLimitPagination(),
        getMembers: offsetLimitPagination(["clubId"]),
        getReactions: offsetLimitPagination([
          "postId",
          "commentId",
          "replyId",
          "voteType",
        ]),
        getUserWatchlist: offsetLimitPagination(["userId", "type"]),
        getTick3Experts: offsetLimitPagination(),
        getUpcomingEvents: offsetLimitPagination(),
        getClubsOfUser: offsetLimitPagination(["userId", "entitlement"]),
        getApprovalRequests: offsetLimitPagination(["filter"]),
        // Cursor Base Pagination
        getPostByHashTagSinceDate: cursorLimitPagination(["hashTagName"]),
        getProfilePostsSinceDate: cursorLimitPagination(["data"]),
        getClubPostsSinceDate: cursorLimitPagination(["clubId"]),
        getPostByDollarTagSinceDate: cursorLimitPagination(["dollarTagId"]),
        getPostsByFilters: cursorLimitPagination(["clubId", "filter"]),
        // Relay Style Pagination
        getCommentsByPostId: relayStylePagination(["postId"]),
        getRepliesByCommentId: relayStylePagination(["commentId"]),
      },
    },
  },
});

export default cache;
